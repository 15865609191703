import { actions } from './actions'
import { add } from './add'
import { arrowBack } from './arrow-back'
import { burger } from './burger'
import { checked } from './checked'
import { closeDialog } from './close'
import { deleteIcon } from './delete'
import { download } from './download'
import { edit } from './edit'
import { emblem } from './emblem'
import { eye } from './eye'
import { fb } from './facebook'
import { file } from './file'
import { herald } from './herald'
import { hi } from './hi'
import { info } from './info'
import { instagram } from './instagram'
import { logoWhite } from './logo-white'
import { more } from './more'
import { navArrow } from './nav-arrow'
import { paginationArrow } from './pagination-arrow'
import { penLogo } from './pen-logo'
import { plus } from './plus'
import { polygon } from './polygon'
import { save } from './save'
import { SvgIcon } from './svg-icons.enum'
import { telegram } from './telegram'
import { twitter } from './twitter'

import { search } from 'src/app/shared/svg-icons/icons/search'
import { support } from 'src/app/shared/svg-icons/icons/support'

export const icons = {
  [SvgIcon.SEARCH]: search,
  [SvgIcon.SUPPORT]: support,
  [SvgIcon.POLYGON]: polygon,
  [SvgIcon.ACTIONS]: actions,
  [SvgIcon.ARROW_BACK]: arrowBack,
  [SvgIcon.ADD]: add,
  [SvgIcon.CLOSE]: closeDialog,
  [SvgIcon.EYE]: eye,
  [SvgIcon.CHECKED]: checked,
  [SvgIcon.DELETE]: deleteIcon,
  [SvgIcon.MORE]: more,
  [SvgIcon.DOWNLOAD]: download,
  [SvgIcon.FILE]: file,
  [SvgIcon.EDIT]: edit,
  [SvgIcon.PAGINATION_ARROW]: paginationArrow,
  [SvgIcon.BURGER]: burger,
  [SvgIcon.SAVE]: save,
  [SvgIcon.EMBLEM]: emblem,
  [SvgIcon.LOGO_WHITE]: logoWhite,
  [SvgIcon.HI]: hi,
  [SvgIcon.HERALD]: herald,
  [SvgIcon.PEN_LOGO]: penLogo,
  [SvgIcon.NAV_ARROW]: navArrow,
  [SvgIcon.FB]: fb,
  [SvgIcon.TELEGRAM]: telegram,
  [SvgIcon.TWITTER]: twitter,
  [SvgIcon.INSTAGRAM]: instagram,
  [SvgIcon.INFO]: info,
  [SvgIcon.PLUS]: plus,
}
