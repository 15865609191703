export const support = `<svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_201_938)">
<path d="M24.4053 48.7824C34.9196 48.7824 40.7767 48.7824 44.5767 44.8923C48.3767 41.0022 48.4053 35.0799 48.4053 24.3966C48.4053 13.7132 48.4053 7.76194 44.5767 3.90087C40.7767 0.0107422 34.9196 0.0107422 24.4053 0.0107422C13.891 0.0107422 8.03382 0.0107422 4.23384 3.90087C0.405272 7.76194 0.405273 13.7132 0.405273 24.3966C0.405273 35.0799 0.405272 41.0312 4.23384 44.8923C8.03382 48.7824 13.891 48.7824 24.4053 48.7824Z" fill="black"/>
<path d="M29.5095 33.8227L26.8103 36.0875L23.3453 31.9441L18.5953 35.9653L14.6704 16.1382L29.5095 33.8227Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.3022 30.2757C29.7848 29.0248 29.9763 26.8093 28.7287 25.3225C27.4811 23.8356 25.266 23.6395 23.7767 24.8823L15.709 15.2676L34.4215 22.6405L32.8216 28.5957L29.3423 31.5152L28.3022 30.2757Z" fill="white"/>
<path d="M30.051 32.3751L33.5479 29.4409L34.0369 30.0237L30.5401 32.9579L30.051 32.3751Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_201_938">
<rect width="48" height="49.0213" fill="white" transform="translate(0.405273)"/>
</clipPath>
</defs>
</svg>
`
