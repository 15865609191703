export const penLogo = `<svg width="94" height="96" viewBox="0 0 94 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1031_822)">
<path d="M47.3548 95.8226C67.9519 95.8226 79.4258 95.8226 86.8699 88.202C94.3139 80.5814 94.3698 68.9799 94.3698 48.0518C94.3698 27.1235 94.3698 15.4652 86.8699 7.90148C79.4258 0.280884 67.9519 0.280884 47.3548 0.280884C26.7578 0.280884 15.2839 0.280884 7.83985 7.90148C0.33984 15.4652 0.339844 27.1235 0.339844 48.0518C0.339844 68.9799 0.33984 80.6383 7.83985 88.202C15.2839 95.8226 26.7578 95.8226 47.3548 95.8226Z" fill="black"/>
<path d="M57.3542 66.5173L52.0667 70.9541L45.2788 62.8373L35.9739 70.7146L28.2852 31.8741L57.3542 66.5173Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M54.99 59.5688C57.8942 57.1185 58.2695 52.7784 55.8254 49.8657C53.3814 46.953 49.0421 46.5689 46.1247 49.0035L30.3203 30.1686L66.9774 44.6119L63.8433 56.2779L57.0275 61.997L54.99 59.5688Z" fill="white"/>
<path d="M58.4155 63.6817L65.2656 57.9337L66.2236 59.0754L59.3735 64.8233L58.4155 63.6817Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1031_822">
<rect width="94.03" height="95.989" fill="white" transform="translate(0.339844 0.280884)"/>
</clipPath>
</defs>
</svg>
`
